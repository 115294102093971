import React from "react";
import "./CareerInterestProgressBar.css";

const CareerInterestProgressBar = (props) => {
  const scorePer = isFinite(props.scorePer) ? props.scorePer : 0;
  let progressBarColor;

  if (scorePer <= 30) {
    progressBarColor = "red-bar";
  } else if (scorePer > 30 && scorePer <= 50) {
    progressBarColor = "yellow-bar";
  } else if (scorePer > 50 && scorePer <= 70) {
    progressBarColor = "lightgreen-bar";
  } else {
    progressBarColor = "green-bar";
  }

  return (
    <div className="careerinterestcontainer">
      <div className="careerinterestprogressbarcontainer">
        <div
          className={`careerinterestprogressbarblock ${progressBarColor}`}
          style={{ width: `${scorePer}%` }}
        ></div>
      </div>
    </div>
  );
};

export default CareerInterestProgressBar;
