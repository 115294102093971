const Footer = () => {
  return (
    <div className="row text-center">
      <div className="col-md-4"></div>
      <div className="col-md-4">
        <p>
          Reach us for support at :{" "}
          <a href="mailto:hello@lifeskillsbharat.com">
            hello@lifeskillsbharat.com
          </a>
        </p>
      </div>
      <div className="col-md-4"></div>
    </div>
  );
};
export default Footer;
