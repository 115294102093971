import React, { useEffect, useState, useRef } from "react";
import axios from "axios"; // Assuming you're using Axios for HTTP requests
import "./App.css";
import { InfinitySpin } from "react-loader-spinner";
import ReactPlayer from "react-player";
import FeedbackComponent from "./FeedbackComponent";
import AudioPlayer from "./AudioPlayer";
import CustomAudioPlayer from "./CustomAudioPlayer";
import { buildQueries } from "@testing-library/react";
import Footer from "./Footer";
import CareerReport from "./CareerReport";
import { useReactToPrint } from "react-to-print";
import StressTestReport from "./StressTestReport";
import nonisIELTSscoring from "./nonisIELTSscoring";
const positiveFeedback = [
  "You are nailing it! Your video presence is great. Keep going!",
  "Fantastic job! Your energy and enthusiasm levels are nice. Great work!",
  "Your video presence is spot on. You are mastering the art of engagement. Well done!",
  "Impressive! Your video presence is professional and personable. Keep it up!",
  "Excellent video presence! You've made a strong first impression. Well done!",
];

const negativeFeedback = [
  "Your video presence could be improved. Try smiling more to connect with the audience.",
  "To enhance your video presence, focus on maintaining a friendly and engaging demeanor.",
  "Your video presence needs improvement. Practice smiling naturally while speaking.",
  "Your video presence needs improvement. A warmer smile will make a big difference.",
  "Your video presence could be improved. Try injecting more enthusiasm into your delivery.",
];

function UserReport() {
  const componentRef = useRef();
  const [data, setData] = useState([]);
  const [username, setUsername] = useState();
  const [isIELTSscoring, setisIELTSscoring] = useState(false);
  const [mediaArray, setMediaArray] = useState([]);
  const [OnlymediaArray, setOnlyMediaArray] = useState([]);
  const [textArray, setTextArray] = useState([]);
  const [loader, setLoader] = useState(true);
  const [showNote, setShowNote] = useState(false);

  console.log("componentRef", componentRef.current);

  let handlePrint = useReactToPrint({
    contentRef: componentRef,
  });

  const [categoryCounts, setCategoryCounts] = useState({});
  const [isCareerInterestTest, setIsCareerInterestTest] = useState(false);
  const [isStressTest, setIsStressTest] = useState(false);
  const [stressCount, setStressCount] = useState(0);
  const [isOldTest, setIsOldTest] = useState(false);
  // Mapping of questions to categories
  const categoryMap = {
    1: "R",
    2: "I",
    3: "A",
    4: "S",
    5: "E",
    6: "C",
    7: "R",
    8: "A",
    9: "C",
    10: "E",
    11: "I",
    12: "S",
    13: "S",
    14: "R",
    15: "C",
    16: "E",
    17: "A",
    18: "I",
    19: "E",
    20: "S",
    21: "I",
    22: "R",
    23: "A",
    24: "C",
    25: "C",
    26: "I",
    27: "A",
    28: "S",
    29: "E",
    30: "R",
    31: "A",
    32: "R",
    33: "I",
    34: "S",
    35: "C",
    36: "E",
    37: "R",
    38: "C",
    39: "I",
    40: "S",
    41: "A",
    42: "E",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        const senderId = queryParams.get("senderId");
        const starttime = queryParams.get("starttime");
        //
        const response = await axios.post(
          //for Production
          //"https://hnylearning.com/awarenessHubBackend/getData/getDetailedUserReportWithTextResponse/",
          //for interview.lifebharat.com
          //for test
          "https://hnylearning.com/awarenessHubBackend_UAT/getData/getDetailedUserReportWithTextResponse2/",
          //"https://botapi.mygreenhorn.in/getData/getDetailedUserReportWithTextResponse2/",
          //for botBuild
          //"https://hnylearning.com/telegramBot_LSB/report",
          {
            senderId: senderId,
          },
          {
            headers: {
              authtoken: "Awareness-Hub@7895",
              "Content-Type": "application/json",
            },
          }
        );

        console.log("response", response);

        //Making Changes for UAT

        setData(response.data.data);

        const MainData = response.data.data;
        console.log("MainData", MainData);

        const arr = MainData[0].response;
        const username = arr.userName;
        console.log("username", username);
        setUsername(username);

        const isIELTSscoring = arr.isIELTSscoring;
        console.log("isIELTSscoring", isIELTSscoring);
        setisIELTSscoring(isIELTSscoring);
        const mediaArray = arr.multimediaStagesObj;
        console.log("mediaArray", mediaArray);

        setMediaArray(mediaArray);
        // const hasRevisedOverallScore = mediaArray.some((video) =>
        //   video.hasOwnProperty("Revised_Overall_score")
        // );

        // Set showNote to true if Revised_Overall_score is found
        // if (hasRevisedOverallScore) {
        //   setShowNote(true);
        // }
        const OnlymediaArray = arr.onlyMediaStagesObj;
        console.log("OnlymediaArray", OnlymediaArray);
        setOnlyMediaArray(OnlymediaArray);
        const textArray = arr.textStagesObj;
        //This has two array which is relatd to question part and user information
        console.log("textArray", textArray);
        setTextArray(textArray);
        const textarrayfirstQuestion = textArray[0].data[0].BotText;
        const questionsObj = textArray[0].data;
        console.log("questionsObj", questionsObj);
        if (
          textarrayfirstQuestion ==
          "I like to repair toy cars, robots, etc. when they are broken"
        ) {
          console.log("textarrayfirstQuestion", textarrayfirstQuestion);
          console.log("textArray career interest test");
          //evaluateScores();

          setIsCareerInterestTest(true);

          const MainTextArray = textArray[0];

          const R_cat = {
            1: MainTextArray?.data[0],
            2: MainTextArray?.data[6],
            3: MainTextArray?.data[13],
            4: MainTextArray?.data[21],
            5: MainTextArray?.data[29],
            6: MainTextArray?.data[31],
            7: MainTextArray?.data[36],
          };
          console.log("R_cat", R_cat);
          let Rcount = 0;

          for (const key in R_cat) {
            if (R_cat[key]?.Result === "RIGHT") {
              Rcount++;
            }
          }

          console.log("Count of 'right' Results:", Rcount);

          const I_cat = {
            1: MainTextArray?.data[1],
            2: MainTextArray?.data[10],
            3: MainTextArray?.data[17],
            4: MainTextArray?.data[20],
            5: MainTextArray?.data[25],
            6: MainTextArray?.data[32],
            7: MainTextArray?.data[38],
          };
          console.log("I_cat", I_cat);
          let Icount = 0;

          for (const key in I_cat) {
            if (I_cat[key]?.Result === "RIGHT") {
              Icount++;
            }
          }

          console.log("Count of 'right' Results:I_cat", Icount);

          const A_cat = {
            1: MainTextArray?.data[2],
            2: MainTextArray?.data[7],
            3: MainTextArray?.data[16],
            4: MainTextArray?.data[22],
            5: MainTextArray?.data[26],
            6: MainTextArray?.data[30],
            7: MainTextArray?.data[40],
          };
          console.log("A_cat", A_cat);
          let Acount = 0;

          for (const key in A_cat) {
            if (A_cat[key]?.Result === "RIGHT") {
              Acount++;
            }
          }

          console.log("Count of 'right' Results:Acount", Acount);

          const S_cat = {
            1: MainTextArray?.data[3],
            2: MainTextArray?.data[11],
            3: MainTextArray?.data[12],
            4: MainTextArray?.data[19],
            5: MainTextArray?.data[27],
            6: MainTextArray?.data[33],
            7: MainTextArray?.data[39],
          };
          console.log("S_cat", S_cat);
          let Scount = 0;

          for (const key in S_cat) {
            if (S_cat[key]?.Result === "RIGHT") {
              Scount++;
            }
          }

          console.log("Count of 'right' Results:Scount", Scount);

          const E_cat = {
            1: MainTextArray?.data[4],
            2: MainTextArray?.data[9],
            3: MainTextArray?.data[15],
            4: MainTextArray?.data[18],
            5: MainTextArray?.data[28],
            6: MainTextArray?.data[35],
            7: MainTextArray?.data[41],
          };
          console.log("E_cat", E_cat);
          let Ecount = 0;

          for (const key in E_cat) {
            if (E_cat[key]?.Result === "RIGHT") {
              Ecount++;
            }
          }

          console.log("Count of 'right' Results:Ecount", Ecount);

          const C_cat = {
            1: MainTextArray?.data[5],
            2: MainTextArray?.data[8],
            3: MainTextArray?.data[14],
            4: MainTextArray?.data[23],
            5: MainTextArray?.data[24],
            6: MainTextArray?.data[34],
            7: MainTextArray?.data[37],
          };
          console.log("C_cat", C_cat);
          let Ccount = 0;

          for (const key in C_cat) {
            if (C_cat[key]?.Result === "RIGHT") {
              Ccount++;
            }
          }

          console.log("Count of 'right' Results:Ccount", Ccount);
          const maxCount = Math.max(
            Rcount,
            Icount,
            Acount,
            Scount,
            Ecount,
            Ccount
          );
          console.log("Maximum count among all categories:", maxCount);

          const categoryCounts = {
            R: Rcount,
            I: Icount,
            A: Acount,
            S: Scount,
            E: Ecount,
            C: Ccount,
          };

          setCategoryCounts(categoryCounts);

          const sortedCategories = Object.entries(categoryCounts).sort(
            (a, b) => b[1] - a[1]
          );

          const topTwoCategories = sortedCategories.slice(0, 2);

          console.log("Top 2 categories:", topTwoCategories);
        } else if (
          textarrayfirstQuestion ==
          "I worry about things working out the way I want them to."
        ) {
          setIsStressTest(true);
          let Stresscount = 0;

          for (const key in questionsObj) {
            if (questionsObj[key]?.Result === "RIGHT") {
              Stresscount++;
            }
          }

          console.log("Count of 'right' Stresscount:", Stresscount);
          setStressCount(Stresscount);
        } else {
          setIsOldTest(true);
          console.log("Not Career Interest Test");
        }
        setLoader(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const scoresData = [{}];

    fetchData();
  }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  // Initialize state for scores
  const [categoryScores, setCategoryScores] = useState({});

  // Function to evaluate scores
  const evaluateScores = () => {
    const scores = {};

    // Iterate through the questions and calculate scores
    textArray.forEach((question) => {
      const category = categoryMap[question.id];
      if (!scores[category]) {
        scores[category] = 0;
      }
      scores[category] += question.score;
    });

    setCategoryScores(scores);
  };

  const getCategory = (value) => {
    if (value >= 0 && value < 4) {
      return "Low";
    } else if (value >= 4 && value < 8) {
      return "Moderate";
    } else {
      return "High";
    }
  };

  return (
    <>
      {loader ? (
        <div style={{ display: "grid", placeItems: "center", height: "100vh" }}>
          <InfinitySpin
            visible={true}
            width="150"
            color="#02ccfe"
            ariaLabel="infinity-spin-loading"
          />
        </div>
      ) : null}

      <div className="main-container">
        {loader ? null : (
          <div style={{ paddingTop: "5px" }} className="sub-container">
            <div className="row text-center">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                {" "}
                <img width={150} className="logo" src="./hnylogo.png" />
              </div>
              <div className="col-md-4"></div>
            </div>
            <hr />

            {isCareerInterestTest ? (
              <>
                <button
                  onClick={handlePrint}
                  style={{
                    background: "black",
                    color: "white",
                    borderRadius: 5,
                  }}
                >
                  Download PDF
                </button>
                <div ref={componentRef}>
                  <div
                    style={{ marginTop: 30, paddingLeft: 25 }}
                    className="row"
                  >
                    <p className="main-heading">
                      Here’s a quick report of <b>{username}</b>
                    </p>
                  </div>
                  <hr />
                  <CareerReport categoryCounts={categoryCounts} />
                </div>
              </>
            ) : null}

            {isStressTest ? (
              <StressTestReport stressCount={stressCount} />
            ) : null}

            {isOldTest ? (
              <div style={{ marginTop: 10, padding: 0 }} class="container">
                <div style={{ marginTop: 30 }} className="row">
                  <p className="main-heading">
                    Here’s a quick report of <b>{username}</b>
                  </p>
                </div>
                <ul
                  style={{ border: 0 }}
                  class="nav nav-tabs"
                  id="myTab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="video-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#video"
                      type="button"
                      role="tab"
                      aria-controls="video"
                      aria-selected="false"
                    >
                      Video Inputs
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="text-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#text"
                      type="button"
                      role="tab"
                      aria-controls="text"
                      aria-selected="true"
                    >
                      Text Inputs
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade"
                    id="text"
                    role="tabpanel"
                    aria-labelledby="text-tab"
                  >
                    <div>
                      {/* <h2>Question Categories and Scores</h2> */}
                      <button onClick={evaluateScores}>Evaluate Scores</button>
                      <div>
                        {Object.entries(categoryScores).map(
                          ([category, score]) => (
                            <p key={category}>
                              {category}: {score}
                            </p>
                          )
                        )}
                      </div>
                    </div>

                    <div style={{ marginTop: 25 }} className="mt-10">
                      {textArray.map((section, sectionIndex) => {
                        const hasResults = section.data.some(
                          (item) => item.Result !== undefined
                        );
                        return (
                          <div key={sectionIndex}>
                            {/* <h2
                              style={{
                                fontSize: 23,
                                fontWeight: 600,
                                marginTop: 50,
                              }}
                            >
                              {section.sectionName}
                            </h2> */}

                            <hr />

                            {hasResults &&
                              section.sectionScoring !== 0 &&
                              section.sectionScoring !== "" && (
                                <>
                                  <p className="fontsize">
                                    Section Score: <b>{section.sectionScore}</b>{" "}
                                    out of <b>{section.sectionScoring}</b> (
                                    {Math.round(
                                      (section.sectionScore /
                                        section.sectionScoring) *
                                        100
                                    )}
                                    %)
                                  </p>
                                </>
                              )}
                            {section.data.map((item, itemIndex) => (
                              <div key={itemIndex}>
                                <p className="question">
                                  <b style={{ width: 150 }}>
                                    {`Question ${itemIndex + 1}`}:{" "}
                                  </b>

                                  {`${item.BotText}`}
                                </p>
                                <p className="answertext">
                                  <b>Answer: </b>
                                  {item.userText}&nbsp;
                                  <span>
                                    {item.Result ? (
                                      <>
                                        {item.Result == "RIGHT" ? (
                                          <>
                                            (
                                            <span
                                              style={{
                                                backgroundColor: "rgb(0 255 8)",
                                              }}
                                            >
                                              Right
                                            </span>
                                            )
                                          </>
                                        ) : (
                                          <>
                                            (
                                            <span
                                              style={{
                                                backgroundColor: "rgb(255 0 4)",
                                              }}
                                            >
                                              Wrong
                                            </span>
                                            )
                                          </>
                                        )}
                                      </>
                                    ) : null}
                                  </span>
                                </p>
                                {/* {item.Score !== undefined && (
                              <p>
                                <strong>Score:</strong> {item.Score}
                              </p>
                            )}
                            <p>
                              <strong>Time:</strong> {item.time}
                            </p> */}
                              </div>
                            ))}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    class="tab-pane fade show active"
                    id="video"
                    role="tabpanel"
                    aria-labelledby="video-tab"
                  >
                    {/* {isIELTSscoring == true ? (
                      <isIELTSscoring mediaArray={mediaArray} />
                    ) : (
                      <nonisIELTSscoring mediaArray={mediaArray} />
                    )} */}
                    <div style={{ marginTop: 25 }} className="mt-10">
                      {mediaArray.map((item, index) => (
                        <div key={index} className="faq-item">
                          {item.type === "videoInput" && (
                            <>
                              <p style={{ marginTop: 30 }} className="question">
                                <b>{`Question ${index + 1}`}</b>
                                {`: ${item.BotText}`}
                              </p>

                              <div
                                style={{ marginTop: 16 }}
                                className="NonIELTS"
                              >
                                <div className="row">
                                  <div className="col-md-12 scoresize">
                                    <div>
                                      {item.SampleAnswer ? (
                                        <p style={{ fontSize: 15 }}>
                                          <b>Benchmark Response:</b>{" "}
                                          {item.SampleAnswer}
                                        </p>
                                      ) : null}
                                      <hr />
                                      Overall Interview Score:&nbsp;
                                      {item.FinalOverallScore ? (
                                        <>
                                          {" "}
                                          <b> {item.FinalOverallScore}</b>
                                        </>
                                      ) : (
                                        <>{item.wordCounterror}</>
                                      )}
                                      <br />
                                      <p>
                                        Overall interview is based on{" "}
                                        <b>Relevance</b>,
                                        <b> Communication Impact</b> and
                                        <b> Executive Presence</b>
                                      </p>
                                      <hr />
                                      <p>
                                        <b>Relevance: </b> This is based on
                                        suitabilty of content
                                        <br />
                                        <b>Relevance score: </b>
                                        {item.Relevance ? (
                                          <>
                                            {" "}
                                            {getCategory(item.Relevance)} (
                                            {item.Relevance})
                                          </>
                                        ) : (
                                          <>{item.wordCounterror}</>
                                        )}{" "}
                                        <br />
                                        {item.relevance_suggestionPatternvalue ? (
                                          <>
                                            {" "}
                                            <b>Suggestion: </b>
                                            {
                                              item.relevance_suggestionPatternvalue
                                            }
                                          </>
                                        ) : null}
                                      </p>
                                      <p>
                                        <b>Communication Impact: </b> This is
                                        based on grammer, word choice, sentence
                                        structure and fluency
                                        <br />
                                        <b>Communication Impact score: </b>
                                        {item.communicationScore ? (
                                          <>
                                            {getCategory(
                                              item.communicationScore
                                            )}{" "}
                                            ({item.communicationScore}) <br />
                                          </>
                                        ) : (
                                          <>{item.wordCounterror}</>
                                        )}
                                        {item.comsug_value ? (
                                          <>
                                            {" "}
                                            <b>Suggestion: </b>
                                            {item.comsug_value}
                                          </>
                                        ) : null}
                                      </p>
                                      <p>
                                        <b>Executive Presence: </b> This is
                                        based on tone, professionalism and
                                        authority
                                        <br />
                                        <b>Executive Presence score: </b>
                                        {item.executivePresenceScore ? (
                                          <>
                                            {" "}
                                            {getCategory(
                                              item.executivePresenceScore
                                            )}{" "}
                                            ({item.executivePresenceScore}){" "}
                                            <br />
                                          </>
                                        ) : (
                                          <>{item.wordCounterror}</>
                                        )}
                                        {item.epsugg_value ? (
                                          <>
                                            <b>Suggestion: </b>
                                            {item.epsugg_value}
                                          </>
                                        ) : null}
                                      </p>
                                      {item.dominantEmotion ? (
                                        <>
                                          <p>
                                            <b>Predominant Emotion: </b>
                                            {item.dominantEmotion ===
                                            "happy" ? (
                                              <b>
                                                High level of energy and
                                                confidence
                                              </b>
                                            ) : null}
                                            {item.dominantEmotion === "fear" ? (
                                              <b>Low level of confidence</b>
                                            ) : null}
                                            {item.dominantEmotion ===
                                            "disgust" ? (
                                              <b>
                                                Negative body language and
                                                disengagement
                                              </b>
                                            ) : null}
                                            {item.dominantEmotion ===
                                            "angry" ? (
                                              <b>
                                                Negative body language and
                                                Aggression
                                              </b>
                                            ) : null}
                                            {item.dominantEmotion === "sad" ? (
                                              <b>
                                                Low level of  energy and
                                                confidence
                                              </b>
                                            ) : null}
                                            {item.dominantEmotion ===
                                            "surprise" ? (
                                              <b>
                                                Negative body language
                                                presenting low level of
                                                readiness
                                              </b>
                                            ) : null}
                                          </p>
                                        </>
                                      ) : null}
                                      <hr />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <br />
                              <div className="mt-10">
                                <div
                                  style={{ maxWidth: 480 }}
                                  className="player-wrapper"
                                >
                                  <ReactPlayer
                                    className="react-player"
                                    controls
                                    url={item.videoUrl}
                                    width="100%"
                                    height="100%"
                                    playsInline
                                  />
                                </div>
                              </div>
                            </>
                          )}

                          <div className="mt-10">
                            {item.type === "voiceInput" && (
                              <div>
                                {item.SampleAnswer ? (
                                  <p style={{ fontSize: 15 }}>
                                    <b>Benchmark Response:</b>{" "}
                                    {item.SampleAnswer}
                                  </p>
                                ) : null}
                                <hr />
                                {item.relevanceAnalysis ? (
                                  <p>
                                    <b>
                                      AI-Generated 'Relevance Analysis' Summary
                                      :
                                    </b>{" "}
                                    {item.relevanceAnalysis}
                                  </p>
                                ) : null}

                                {item.audioAnalysis ? (
                                  <p>
                                    <b>
                                      AI-generated 'Audio Analysis' Summary:
                                    </b>{" "}
                                    {item.audioAnalysis
                                      ? item.audioAnalysis
                                      : parseInt(
                                          item.Overall_score < 10
                                            ? item.Overall_score
                                            : (item.Overall_score / 10).toFixed(
                                                1
                                              )
                                        ) > 5
                                      ? "🙌 Congrats. Your voice input and quality-of-communication looks good. keep it up "
                                      : "❌ Sorry. Your voice input and quality-of-communication needs betterment. Keep practising"}
                                  </p>
                                ) : null}

                                <div className="mt-10">
                                  <audio controls>
                                    <source
                                      src={item.audioUrl}
                                      type="audio/mpeg"
                                    />
                                  </audio>
                                </div>
                                <hr />
                              </div>
                            )}
                          </div>
                        </div>
                      ))}

                      <hr />

                      <div>
                        <p>
                          <b>* Definitions of Key Concepts: </b>
                        </p>
                        <p>
                          <b>AI-generated Relevance analysis:</b> Our algorithm
                          checks if your answer is relevant to the question.
                        </p>{" "}
                        <p>
                          <b>AI-generated Communication analysis:</b> Our
                          algorithm analyzes how well you spoke, including your
                          grammar, pronunciation, and word choice
                        </p>
                        <p>
                          <b>AI-generated Executive Presence analysis: </b>
                          Our algorithm assesses your body language, like eye
                          contact and facial expressions.
                        </p>{" "}
                        <p>
                          <b>Disclaimer:</b> While our algorithms provide
                          valuable insights, human judgment is essential for a
                          comprehensive analysis. Algorithms may not accurately
                          capture all nuances of spoken language or non-verbal
                          cues. We recommend consulting a human expert for a
                          complete evaluation.
                        </p>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <Footer />
          </div>
        )}
      </div>
    </>
  );
}

export default UserReport;
