import react from "react";
import CareerInterestProgressBar from "./CareerInterestProgressBar";
import "./CareerReport.css";
const CareerReport = ({ categoryCounts }) => {
  console.log("categoryCounts", categoryCounts);
  console.log("categoryCounts.R", categoryCounts.R);

  const sortedCategories = Object.entries(categoryCounts).sort(
    (a, b) => b[1] - a[1]
  );
  const [firstmax, secondmax] = sortedCategories
    .slice(0, 2)
    .map((item) => ({ name: item[0], value: item[1] }));

  return (
    <div>
      <div className="col-md-12 section page-break">
        <div className="text-container">
          <p className="sub-title-text">
            Welcome to your career interest report! There&#39;s a lot of
            information here, so take your time and read through it at your own
            pace.
          </p>
          <p className="pt-20 sub-description-text">
            There are 6 total interest areas, each with its own set of typical
            work tasks, roles, and values. Some of these interest areas will
            appeal to you, while others will be less attractive. Choosing a
            career which is a good match for your interest profile ensures that
            you enjoy your daily work and get satisfaction out of your
            accomplishments.
          </p>
          <p style={{ marginTop: 20 }}>So, let's get started!</p>
          <img
            className="whileprint center-image"
            width={550}
            src="https://test1.mygreenhorn.in/GHcontent/careerreport/sp1.png"
            alt=""
          />
        </div>
      </div>
      <div className="SoftSkillsContainer">
        <div
          className="mainContainer page-break"
          style={{
            paddingTop: 10,
            paddingLeft: 40,
            paddingRight: 40,
          }}
        >
          <div className="row">
            <div className="row main-row-container">
              <p>
                <span className="span-heading">
                  REALISTIC: Hands on knowledge, Building, Physical activity,
                  Outdoors
                </span>

                <br />
                <span className="span-color-text-green">
                  The bar shows how extraverted you are. The more to the right
                  your score is, the higher you score on extraversion.
                </span>
              </p>
              <div className="row">
                <b>Score: {(categoryCounts.R / 7) * 100}</b>
              </div>
              <div className="print-row">
                <div className="print-column print-text-right">
                  <div className="print-bar-text">0</div>
                </div>
                <div className="print-column print-progress-container">
                  <div className="careerinterestprogressblock">
                    <div className="individual-progress-block">
                      <CareerInterestProgressBar
                        isPrcntShow={true}
                        scorePer={Math.round((categoryCounts.R / 7) * 100)}
                      />
                    </div>
                  </div>
                </div>
                <div className="print-column print-text-left">
                  <div className="print-bar-text">100</div>
                </div>
              </div>

              <p>
                People with a Realistic (R) interest type prefers practical,
                hands-on work and enjoys solving mechanical or technical
                problems. For example, a mechanic who enjoys fixing cars or a
                construction worker building structures would thrive in this
                category. These individuals often find satisfaction in jobs
                where they can directly interact with tools, machines, or
                physical environments.
              </p>
              <div className="pt-40">
                Careers: The following are a few areas where such individuals
                can perform well. Please note that this is not an exhaustive
                list:
                <ul>
                  <li className="score-li">Agriculture</li>
                  <li className="score-li">Healthcare</li>
                  <li className="score-li">Manufacturing</li>
                  <li className="score-li">Construction</li>
                  <li className="score-li">Industrial Engineering</li>
                  <li className="score-li">Food and Hospitality</li>
                  <li className="score-li">Public Safety</li>
                </ul>
              </div>
              <img
                className="whileprint center-image"
                style={{
                  maxWidth: "400px",
                  marginTop: 20,
                }}
                src="https://test1.mygreenhorn.in/GHcontent/careerreport/sp2.jpg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div
          className="mainContainer page-break"
          style={{
            paddingTop: 10,
            paddingLeft: 40,
            paddingRight: 40,
          }}
        >
          <div className="row main-row-container">
            <p>
              <span className="span-heading">
                INVESTIGATIVE: Research, Learning, Science, Technology
              </span>
              <br />
              <span className="span-color-text-green">
                Those with an Investigative (I) orientation are curious,
                analytical, and enjoy problem-solving. They are typically drawn
                to fields like science, research, or technology.
              </span>
            </p>
            <div className="row">
              <b>Score: {Math.round((categoryCounts.I / 7) * 100)}</b>
            </div>
            <div className="print-row">
              <div className="print-column print-text-right">
                <div className="print-bar-text">0</div>
              </div>
              <div className="print-column print-progress-container">
                <div className="careerinterestprogressblock">
                  <div className="individual-progress-block">
                    <CareerInterestProgressBar
                      isPrcntShow={true}
                      scorePer={(categoryCounts.I / 7) * 100}
                    />
                  </div>
                </div>
              </div>
              <div className="print-column print-text-left">
                <div className="print-bar-text">100</div>
              </div>
            </div>
            <div className="row pt-40">
              <p>
                Those with an Investigative (I) orientation are curious,
                analytical, and enjoy problem-solving. They are typically drawn
                to fields like science, research, or technology. A biologist
                studying ecosystems or a software developer solving complex
                algorithms are examples of investigative careers. These
                individuals enjoy roles that allow them to think critically and
                engage with data to uncover solutions.
              </p>
            </div>

            <div className="pt-40">
              <p>
                <strong>Careers:</strong> The following are a few areas where
                such individuals can perform well. Please note that this is not
                an exhaustive list:
              </p>
              <ul>
                <li className="score-li">Engineering</li>
                <li className="score-li">Mathematics</li>
                <li className="score-li">Medicine</li>
                <li className="score-li">Marine Biology</li>
                <li className="score-li">Chemistry</li>
                <li className="score-li">Economics</li>
                <li className="score-li">Psychology</li>
              </ul>
            </div>
            <img
              className="whileprint center-image"
              style={{
                maxWidth: "500px",
                marginTop: 50,
              }}
              src="https://test1.mygreenhorn.in/GHcontent/careerreport/sp3.png"
              alt=""
            />
          </div>
        </div>
        <div
          className="mainContainer page-break"
          style={{
            paddingTop: 10,
            paddingLeft: 40,
            paddingRight: 40,
          }}
        >
          <div className="row">
            <div className="row main-row-container">
              <p>
                <span className="span-heading">
                  ARTISTIC: Feeling, Passion, Design, Creation
                </span>
                <br />
              </p>
              <div className="row">
                <b>Score: {Math.round((categoryCounts.A / 7) * 100)}</b>
              </div>
              <div className="print-row">
                <div className="print-column print-text-right">
                  <div className="print-bar-text">0</div>
                </div>
                <div className="print-column print-progress-container">
                  <div className="careerinterestprogressblock">
                    <div className="individual-progress-block">
                      <CareerInterestProgressBar
                        isPrcntShow={true}
                        scorePer={Math.round((categoryCounts.A / 7) * 100)}
                      />
                    </div>
                  </div>
                </div>
                <div className="print-column print-text-left">
                  <div className="print-bar-text">100</div>
                </div>
              </div>
              <div className="row pt-40">
                <p>
                  Artistic (A) types are creative and expressive, preferring
                  careers that allow for self-expression and innovation. A
                  graphic designer creating visual content for a brand or an
                  actor performing in theater would resonate with this type.
                  Artistic individuals thrive in environments where creativity
                  and freedom are encouraged, such as in the arts, writing, or
                  media production.
                </p>
              </div>

              <div className="pt-40">
                <p>
                  <strong>Careers:</strong> The following are a few areas where
                  such individuals can perform well. Please note that this is
                  not an exhaustive list:
                </p>
                <ul>
                  <li className="score-li">Communications</li>
                  <li className="score-li">Fashion Design</li>
                  <li className="score-li">Fine and Performing Arts</li>
                  <li className="score-li">Interior Design</li>
                  <li className="score-li">Architecture</li>
                  <li className="score-li">Media</li>
                  <li className="score-li">Journalism</li>
                </ul>
              </div>
              <img
                className="whileprint center-image"
                style={{
                  maxWidth: "500px",
                  marginTop: 50,
                }}
                src="https://test1.mygreenhorn.in/GHcontent/careerreport/sp4.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div
          className="mainContainer page-break"
          style={{
            paddingTop: 10,
            paddingLeft: 40,
            paddingRight: 40,
          }}
        >
          <div className="row main-row-container">
            <p>
              <span className="span-heading">
                SOCIAL: Conveying, Understanding Others, Communicating,
                Relationships
              </span>
              <br />
            </p>
            <div className="row">
              <b>Score: {Math.round((categoryCounts.S / 7) * 100)}</b>
            </div>
            <div className="print-row">
              <div className="print-column print-text-right">
                <div className="print-bar-text">0</div>
              </div>
              <div className="print-column print-progress-container">
                <div className="careerinterestprogressblock">
                  <div className="individual-progress-block">
                    <CareerInterestProgressBar
                      isPrcntShow={true}
                      scorePer={Math.round((categoryCounts.S / 7) * 100)}
                    />
                  </div>
                </div>
              </div>
              <div className="print-column print-text-left">
                <div className="print-bar-text">100</div>
              </div>
            </div>
            <div className="row pt-40">
              <p>
                Those who identify as Social(S) enjoy helping and interacting
                with others. They often pursue careers in teaching, healthcare,
                or counseling. For instance, a nurse caring for patients in a
                hospital or a teacher shaping young minds in a classroom
                exemplifies the Social personality. These individuals find
                fulfillment in roles that enable them to make a positive impact
                on others’ lives.
              </p>
            </div>

            <div className="pt-40">
              <p>
                <strong>Careers:</strong> The following are a few areas where
                such individuals can perform well. Please note that this is not
                an exhaustive list:
              </p>
              <ul>
                <li className="score-li">Customer Service</li>
                <li className="score-li">Public Relations</li>
                <li className="score-li">Education</li>
                <li className="score-li">Human Resources</li>
                <li className="score-li">Counseling</li>
                <li className="score-li">Nursing</li>
                <li className="score-li">Childcare</li>
              </ul>
            </div>
            <img
              className="whileprint center-image"
              style={{
                maxWidth: "600px",
                marginTop: 150,
              }}
              src="https://test1.mygreenhorn.in/GHcontent/careerreport/sp5.png"
              alt=""
            />
          </div>
        </div>
        <div
          className="mainContainer page-break"
          style={{
            paddingTop: 10,
            paddingLeft: 40,
            paddingRight: 40,
          }}
        >
          <div className="row main-row-container">
            <p>
              <span className="span-heading">
                ENTERPRISING: Taking Action, Understanding Projects, Leading,
                Managing
              </span>
              <br />
            </p>
            <div className="row">
              <b>Score: {Math.round((categoryCounts.E / 7) * 100)}</b>
            </div>
            <div className="print-row">
              <div className="print-column print-text-right">
                <div className="print-bar-text">0</div>
              </div>
              <div className="print-column print-progress-container">
                <div className="careerinterestprogressblock">
                  <div className="individual-progress-block">
                    <CareerInterestProgressBar
                      isPrcntShow={true}
                      scorePer={Math.round((categoryCounts.E / 7) * 100)}
                    />
                  </div>
                </div>
              </div>
              <div className="print-column print-text-left">
                <div className="print-bar-text">100</div>
              </div>
            </div>
            <div className="row pt-40">
              <p>
                The Enterprising (E) type is ambitious, persuasive, and enjoys
                leadership or entrepreneurial roles. A corporate manager
                directing a team or a startup funder building a new business are
                examples of enterprising careers. These individuals excel in
                environments where they can lead, persuade, and drive projects
                forward.
              </p>
            </div>

            <div className="pt-40">
              <p>
                <strong>Careers:</strong> The following are a few areas where
                such individuals can perform well. Please note that this is not
                an exhaustive list:
              </p>
              <ul>
                <li className="score-li">Sales and Marketing</li>
                <li className="score-li">Law</li>
                <li className="score-li">Political Science</li>
                <li className="score-li">International Science</li>
                <li className="score-li">Business and Management</li>
                <li className="score-li">Entrepreneurship</li>
              </ul>
            </div>
            <img
              className="whileprint center-image"
              style={{ maxWidth: "280px" }}
              src="https://test1.mygreenhorn.in/GHcontent/careerreport/sp7.png"
              alt=""
            />
          </div>
        </div>
        <div
          className="mainContainer page-break"
          style={{
            paddingTop: 10,
            paddingLeft: 40,
            paddingRight: 40,
          }}
        >
          <div className="row main-row-container">
            <p>
              <span className="span-heading">
                CONVENTIONAL: Planning, Structuring, Processing Data, Figures
              </span>
              <br />
            </p>
            <div className="row">
              <b>Score: {Math.round((categoryCounts.C / 7) * 100)}</b>
            </div>
            <div className="print-row">
              <div className="print-column print-text-right">
                <div className="print-bar-text">0</div>
              </div>
              <div className="print-column print-progress-container">
                <div className="careerinterestprogressblock">
                  <div className="individual-progress-block">
                    <CareerInterestProgressBar
                      isPrcntShow={true}
                      scorePer={Math.round((categoryCounts.C / 7) * 100)}
                    />
                  </div>
                </div>
              </div>
              <div className="print-column print-text-left">
                <div className="print-bar-text">100</div>
              </div>
            </div>
            <div className="row pt-40">
              <p>
                Those with a Conventional(C) interest type prefer structured,
                detail-oriented work. They are well-suited for careers in
                administration, finance, or data management. An accountant
                balancing financial records or an office manager overseeing
                office operations would thrive in conventional roles. These
                individuals excel in organized, systematic environments where
                attention to detail is key.
              </p>
            </div>

            <div className="pt-40">
              <p>
                <strong>Careers:</strong> The following are a few areas where
                such individuals can perform well. Please note that this is not
                an exhaustive list:
              </p>
              <ul>
                <li className="score-li">Accounting</li>
                <li className="score-li">Auditing</li>
                <li className="score-li">Insurance and Underwriting</li>
                <li className="score-li">Administration</li>
                <li className="score-li">Payroll</li>
                <li className="score-li">Banking Operations</li>
                <li className="score-li">Data Processing</li>
              </ul>
            </div>
            <img
              className="whileprint center-image"
              style={{ maxWidth: "400px" }}
              src="https://test1.mygreenhorn.in/GHcontent/careerreport/sp8.png"
              alt=""
            />
          </div>
        </div>
        <div
          className="text-container mainContainer"
          style={{ padding: 10, marginBottom: 20 }}
        >
          <p>
            Based on your scores, your <b>Primary Interest</b> type is{" "}
            <b>{firstmax.name}</b>
          </p>

          <p style={{ marginTop: 10 }}>
            Please refer to the corresponding section to learn more about your
            career interests
          </p>
          <br />
          <p>
            Based on your scores, your <b>Secondary Interest</b> type is{" "}
            <b>{secondmax.name}</b>
          </p>

          <p style={{ marginTop: 10 }}>
            Please refer to the corresponding section to know more
          </p>
          <br />
          <p>
            Your <b>Primary Interest</b> type is also called your career type.
            Your career type describes the kind of job tasks and activities you
            enjoy doing, as well as what motivates and satisfies you at work.
            Certain personality traits and characteristics are associated with
            each career type.
          </p>
          <br />
          <p>
            Your <b>Secondary Interest</b> type shows what sorts of interests
            you may have, beyond your primary interest area. You can use your
            secondary interest area to get additional ideas and information
            about the career that may fit you.
          </p>
          <img
            className="whileprint center-image"
            style={{ maxWidth: "700px", marginTop: 50 }}
            src="https://test1.mygreenhorn.in/GHcontent/careerreport/sp6.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
export default CareerReport;
