import react from "react";
import CareerInterestProgressBar from "./CareerInterestProgressBar";
import "./CareerReport.css";
import ReactPlayer from "react-player";
const StressTestReport = ({ stressCount }) => {
  console.log("stressCount", stressCount);

  let stressLevel;
  if (stressCount >= 0 && stressCount <= 4) {
    stressLevel = "Normal";
  } else if (stressCount >= 5 && stressCount <= 7) {
    stressLevel = "Moderate";
  } else if (stressCount >= 8) {
    stressLevel = "High";
  }

  return (
    <div>
      <h1>Stress Test Report</h1>
      <p>
        <b>Stress Level: {stressLevel}</b>
      </p>
      <div className="row">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <ReactPlayer
            className="react-player"
            controls
            url={"https://cdn.mygreenhorn.in/journeys/stresstest/s3.mp4"}
            width="100%"
            height="100%"
            playsInline
          />
        </div>
      </div>
      <p style={{ marginTop: 20 }}>
        Now that you know about your stress score, let us look at a few
        techniques to manage stress in your everyday life
      </p>
      <ul>
        <li>
          Maintain a regular sleep schedule, eat a balanced diet, and exercise
          regularly
        </li>
        <li>Use planners to organize tasks and prioritize important ones</li>
        <li>
          Explore creative activities like art, music, and writing to express
          emotions
        </li>
        <li>Reduce excessive screen time to minimize stress and anxiety</li>
        <li>Talk about your feelings with parents and other trusted adults</li>
      </ul>
      <p>
        Remember, finding the right stress management techniques is crucial. If
        you have concerns about your stress levels, seek guidance from a trained
        mental health professional.
      </p>
    </div>
  );
};
export default StressTestReport;
